export const raiseGroundVisuals = [
  {
    name: "Demo",
    markdown: `![Raise ground GIF](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
    `,
  },
  {
    name: "Before & After",
    markdown: `{% beforeAfter beforeImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelBefore.png" afterImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelAfter.png" /%}
    `,
  },
];

export const lowerGroundVisuals = [
  {
    name: "Demo",
    markdown: `![Lower ground GIF](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
    `,
  },
  {
    name: "Before & After",
    markdown: `{% beforeAfter beforeImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelBefore.png" afterImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelAfter.png" /%}
    `,
  },
];

export const raiseLowerGround = {
  order: 33,
  name: "Raise or lower the ground level",
  keywords: "move",
  subcategory: "Properties",
  markdown: `# Raise or lower the ground level

  {% tablessContainer paddingStyles="px-5" %}
  **Who can use this feature?**

  {% inlineAppIcon iconName="user" /%}{% inlineRouterLink articleId="understand-reputation" sectionName="What reputation affects" %}**Reputable users**{% /inlineRouterLink %}.
  {% /tablessContainer %}

  Many {% inlineRouterLink articleId="add-a-land-covering" %}buildings{% /inlineRouterLink %} have multiple {% inlineRouterLink articleId="add-building-levels" %}vertical levels{% /inlineRouterLink %}. Some also include {% inlineRouterLink articleId="add-building-levels" %}below-grade levels{% /inlineRouterLink %}. In Tower Hunt, you can adjust the placement of the ground in relation to a building's vertical levels, moving up or down one level at a time. When {% inlineRouterLink %}viewing multiple properties{% /inlineRouterLink %} on the {% inlineRouterLink articleId="the-role-of-the-property-diagram" %}property diagram{% /inlineRouterLink %}, each is displayed relative to the ground level to give you additional context about things like vertical scale and replacement cost.

  ## Steps

  {% tablessContainer %}
  1. Visit a {% inlineRouterLink articleId="the-role-of-the-property-diagram" %}property diagram{% /inlineRouterLink %}.
  2. {% inlineAppIcon iconName="cursor" /%}{% inlineRouterLink articleId="select-a-property-diagram-part" %}**Select**{% /inlineRouterLink %} a {% inlineRouterLink articleId="add-building-levels" %}building level{% /inlineRouterLink %}.
  3. In the {% inlineRouterLink articleId="understand-the-property-diagram-control-panel" %}control panel{% /inlineRouterLink %}, click {% inlinePropertyDiagramIcon name="raiseGroundLevel" /%} or {% inlinePropertyDiagramIcon name="lowerGroundLevel" /%}.
  4. Click {% inlinePropertyDiagramIcon name="saveEditAction" /%} to save. The ground moves one level in the selected direction.
  {% /tablessContainer %}

  ## Visuals

  ### Raise ground

  {% tabbedContainer tabs=$raiseGroundVisuals /%}

  ### Lower ground

  To lower the ground level, the diagram must already have {% inlineRouterLink articleId="add-building-levels" %}below-grade levels{% /inlineRouterLink %}.

  {% tabbedContainer tabs=$lowerGroundVisuals /%}
  `,
};
